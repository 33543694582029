import React, {useEffect, useState} from "react";
import './index.less'
import {getPaginationItem} from "./interface";

// type size = 'default' | 'small'

export interface IPaginationProps{
    onChange?: (num:number) => void       //  页码或 pageSize 改变的回调，参数是改变后的页码及每页条数
    total?: number              //	数据总数
    defaultCurrent?: number     //  默认的当前页数
    defaultPageSize?: number    //  默认的每页条数
    // hide?: boolean              //  只有一页时是否隐藏分页器
    pageSize?: number           //  每页条数
    // size?:size                  //  当为 small 时，是小尺寸分页
}

const Pagination = (props: IPaginationProps) => {
    const {
        onChange,
        total = 0,
        defaultCurrent = 1,
        // hide = true,
        pageSize = 10,
        // size = 'default'
    } = props
    const [pages,setPages] = useState<any>([])
    const [totalPage,setTotalPage] = useState<number>(1)
    const [active,setActive] = useState<number>(defaultCurrent)
    const [pre,setPre] = useState('•••')
    const [next,setNext] = useState('•••')

    const changePage = (num: number) => {
        if(active < 1 || active > totalPage)return
        setActive(active+Math.min(totalPage-active,num))
        onChange && onChange(active+Math.min(totalPage-active,num))
    }

    useEffect(() => {
        const len: number = Math.floor(total / pageSize) + (total % pageSize && 1 | 0)
        setTotalPage(len)
        if(active<1){
            setActive(1)
        }
        if(active>=totalPage){
            setActive(totalPage)
        }
        setPages(getPaginationItem(active,total,pageSize))
    },[active,pageSize,total])
    const changeSelect = (num: number) => {
        setActive(num)
        setNext('•••')
        setPre('•••')
        onChange && onChange(num)
    }
    return(
        <>
            { total >= 1 &&
            <div className='zc-pagination'>
                <div className={`zc-pagination-item zc-pagination-pre ${active === 1 && 'zc-pagination-item-disable'}`}
                     title='上一页' onClick={() => changePage(-1)}> ＜
                </div>
                {totalPage > 10 && active > 3 &&
                <div className={`zc-pagination-item ${active === 1 && 'zc-pagination-item-active'}`} title='1'
                     onClick={() => changeSelect(1)}>1</div>}
                {active > 4 && totalPage > 10 &&
                <div className='zc-pagination-item-ellipsis'
                     onMouseEnter={() => setNext('<<')}
                     onMouseOut={() => setNext('•••')}
                     onClick={() => changePage(-5)}
                     title='向前5页'
                >{next}
                </div>}

                {
                    pages?.map((i: any, index: number) =>
                        <div
                            className={`zc-pagination-item ${active === i && 'zc-pagination-item-active'}`}
                            key={index}
                            title={i}
                            onClick={() => changeSelect(i)}
                        >{i}</div>)
                }

                {totalPage > 10 && active < totalPage - 3 &&
                <div className='zc-pagination-item-ellipsis'
                     onMouseEnter={() => setPre('>>')}
                     onMouseOut={() => setPre('•••')}
                     onClick={() => changePage(5)}
                     title='向后5页'
                >{pre}
                </div>}

                <div className={`zc-pagination-item ${active === totalPage && 'zc-pagination-item-active'}`}
                     onClick={() => changeSelect(totalPage)}>{totalPage}</div>
                <div
                    className={`zc-pagination-item zc-pagination-next  ${active === totalPage && 'zc-pagination-item-disable'}`}
                    title='下一页' onClick={() => changePage(1)}> ＞
                </div>
                <div className='ml10'> 共 {total} 条记录</div>
            </div>
            || ''}
        </>

    )
}

export default Pagination