

export const getPaginationItem = (active: number,total: number,pageSize: number) => {
    const len: number = Math.floor(total / pageSize) + (total % pageSize && 1 | 0)
   if(active>3 && total>10 && active <= len-3){
       return [active-2,active-1,active,active+1,active+2]
   }
   if(len>10 && active > len-3){
       return [len-5,len-4,len-3,len-2,len-1]
   }
   else {
       let json: any = []
       if (len > 10) {
           json = [1, 2, 3, 4, 5]
       } else {
           for (let i = 1; i < len; i++) {
               json.push(i)
           }
       }
       return json
   }
}